.category-teasers {
  .category-teaser {
    container-type: inline-size;
    overflow: hidden;
    aspect-ratio: 1;
    border-color: var(--background-color);
    border-style: solid;
    border-width: 3px;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      border-width: 4px;
    }

    .font-display {
      font-weight: $font-weight-medium;
      font-size: clamp(1rem, 15cqw, 4rem);
      line-height: 1.2;
      min-height: calc(24px * 2);

      @include media-breakpoint-up(lg) {
        min-height: calc(40px * 2);
      }
    }

    .img-border {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      border: 1px solid var(--text-color);
      z-index: 1;
      pointer-events: none;
    }

    img {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 0.5;
      transform: scale(1);
      transition: all 500ms linear;
      z-index: -1;

      @include media-breakpoint-up(lg) {
        opacity: 0;
      }
    }

    a {
      color: var(--text-color);
      text-decoration: none;
      z-index: 2;

      &::after {
        @include media-breakpoint-down(md) {
          border-image-source: $gradient-primary;
          border-image-slice: 20;
          border-image-width: 2px; /* we need this for safari and or firefox */
        }
      }

      &:active {
        &::after {
          background-color: var(--background-color);
          opacity: 0.5;
        }
      }
    }

    &.disabled {
      opacity: 0.5;

      img {
        opacity: 0;
      }

      &:hover {
        cursor: default;
      }
    }

    &:not(.disabled) {
      &:hover,
      &:focus-within {
        cursor: pointer;
        border-image: $gradient-primary 2 2 round;

        .img-border {
          border: 1px solid transparent;
        }

        img {
          opacity: 0.5;
          transform: scale(1.1);
        }

        a {
          color: var(--text-color);
          text-decoration: none;
          outline: none;
          box-shadow: none;
        }
      }
    }

    svg {
      position: absolute;
      left: 16px;
      bottom: 16px;
      color: var(--text-color);
      width: 32px;
      height: 32px;

      @include media-breakpoint-up(sm) {
        width: 48px;
        height: 48px;
      }
    }
  }
}
