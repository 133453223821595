@charset "UTF-8";
/* bootstrap overwrite */
/* commons styles */
.category-teasers .category-teaser {
  container-type: inline-size;
  overflow: hidden;
  aspect-ratio: 1;
  border-color: var(--background-color);
  border-style: solid;
  border-width: 3px;
  z-index: 1;
}
@media (min-width: 576px) {
  .category-teasers .category-teaser {
    border-width: 4px;
  }
}
.category-teasers .category-teaser .font-display {
  font-weight: 500;
  font-size: clamp(1rem, 15cqw, 4rem);
  line-height: 1.2;
  min-height: calc(24px * 2);
}
@media (min-width: 992px) {
  .category-teasers .category-teaser .font-display {
    min-height: calc(40px * 2);
  }
}
.category-teasers .category-teaser .img-border {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 1px solid var(--text-color);
  z-index: 1;
  pointer-events: none;
}
.category-teasers .category-teaser img {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
  transform: scale(1);
  transition: all 500ms linear;
  z-index: -1;
}
@media (min-width: 992px) {
  .category-teasers .category-teaser img {
    opacity: 0;
  }
}
.category-teasers .category-teaser a {
  color: var(--text-color);
  text-decoration: none;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .category-teasers .category-teaser a::after {
    border-image-source: linear-gradient(122.45deg, #a0c96d 14.06%, #21b5ea 78.85%);
    border-image-slice: 20;
    border-image-width: 2px;
    /* we need this for safari and or firefox */
  }
}
.category-teasers .category-teaser a:active::after {
  background-color: var(--background-color);
  opacity: 0.5;
}
.category-teasers .category-teaser.disabled {
  opacity: 0.5;
}
.category-teasers .category-teaser.disabled img {
  opacity: 0;
}
.category-teasers .category-teaser.disabled:hover {
  cursor: default;
}
.category-teasers .category-teaser:not(.disabled):hover, .category-teasers .category-teaser:not(.disabled):focus-within {
  cursor: pointer;
  border-image: linear-gradient(122.45deg, #a0c96d 14.06%, #21b5ea 78.85%) 2 2 round;
}
.category-teasers .category-teaser:not(.disabled):hover .img-border, .category-teasers .category-teaser:not(.disabled):focus-within .img-border {
  border: 1px solid transparent;
}
.category-teasers .category-teaser:not(.disabled):hover img, .category-teasers .category-teaser:not(.disabled):focus-within img {
  opacity: 0.5;
  transform: scale(1.1);
}
.category-teasers .category-teaser:not(.disabled):hover a, .category-teasers .category-teaser:not(.disabled):focus-within a {
  color: var(--text-color);
  text-decoration: none;
  outline: none;
  box-shadow: none;
}
.category-teasers .category-teaser svg {
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: var(--text-color);
  width: 32px;
  height: 32px;
}
@media (min-width: 576px) {
  .category-teasers .category-teaser svg {
    width: 48px;
    height: 48px;
  }
}

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaLTWXX-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Incn */
@font-face {
  font-family: "museo-sans";
  src: url("../../fonts/MuseoSans500.woff2") format("woff2"), url("../../fonts/MuseoSans500.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "museo-slab";
  src: url("../../fonts/MuseoSlab700.woff2") format("woff2"), url("../../fonts/MuseoSlab700.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "museo-sans";
  src: url("../../fonts/MuseoSans700.woff2") format("woff2"), url("../../fonts/MuseoSans700.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "museo-slab";
  src: url("../../fonts/MuseoSlab500.woff2") format("woff2"), url("../../fonts/MuseoSlab500.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "museo-slab";
  src: url("../../fonts/MuseoSlab900.woff2") format("woff2"), url("../../fonts/MuseoSlab900.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "museo-sans";
  src: url("../../fonts/MuseoSans500Italic.woff2") format("woff2"), url("../../fonts/MuseoSans500Italic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "museo-sans";
  src: url("../../fonts/MuseoSans700Italic.woff2") format("woff2"), url("../../fonts/MuseoSans700Italic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}
.footer-area {
  background-color: #001f47;
  --focus-offset-color: #001f47;
  color: #fff;
}
.footer-area a {
  color: #fff;
}
.footer-area a:hover {
  color: #21b5ea;
}
.footer-area .footer-top {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (min-width: 992px) {
  .footer-area .footer-top {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.footer-area .footer-top .social-media-component {
  border-bottom: 3px solid #0b0e29;
}
@media (min-width: 992px) {
  .footer-area .footer-top .social-media-component {
    padding-bottom: 0;
    border-bottom: none;
  }
}
.footer-area .footer-top .social-media-component .social-links {
  display: none;
}
@media (min-width: 992px) {
  .footer-area .footer-top .social-media-component .social-links {
    display: block;
  }
}
.footer-area .footer-top .social-media-component .social-links ul {
  display: flex;
  gap: 16px;
}
.footer-area .footer-top .social-media-component .social-link:hover {
  text-decoration: none;
}
.footer-area .footer-top .social-media-component .social-link img {
  height: 24px;
}
.footer-area .footer-top .social-media-component .social-link source[srcset*=".svg"] + img {
  filter: brightness(0) invert(1);
}
.footer-area .footer-top .language-switch-container {
  font-size: 16px;
}
.footer-area .footer-top .language-switch-container a {
  color: var(--accent-color);
}
.footer-area .footer-top .language-switch-container a:hover {
  color: var(--accent-hover-color);
}
.footer-area .footer-top .link-list-component {
  border-bottom: 3px solid #0b0e29;
}
@media (min-width: 992px) {
  .footer-area .footer-top .link-list-component {
    padding-left: 20px;
    border-bottom: none;
    border-left: 3px solid #0b0e29;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer-area .footer-top .link-list-component:last-child {
    margin-right: 0;
  }
}
.footer-area .footer-top .link-list-component .list-group {
  display: none;
}
@media (min-width: 992px) {
  .footer-area .footer-top .link-list-component .list-group {
    display: block;
  }
}
.footer-area .footer-top .link-list-component .list-group-item {
  font-size: 16px;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .footer-area .footer-top .link-list-component .list-group-item:last-child {
    margin-bottom: 0;
  }
}
.footer-area .footer-top .footer-accordion-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.footer-area .footer-top .footer-accordion-head::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNzM4NTEgOS44MDMxMkwxMS45MTAzIDUuMjU3NjdDMTIuMDE0MyA1LjExNDEyIDEyLjAxNDMgNC45MjI3NCAxMS45MTAzIDQuNzY3MjNMOC43Mzg1MSAwLjIwOTgxOEM4LjU5NTUyIC0wLjAwNTQ5MzQyIDguMjgzNTUgLTAuMDY1MzAyMSA4LjA2MjU2IDAuMDc4MjM4NkM3Ljg0MTU4IDAuMjIxNzc5IDcuNzYzNTggMC40OTY4OTkgNy45MTk1NyAwLjcwMDI0OEwxMC41OTc0IDQuNTM5OTZMMC41MDgyNDQgNC41Mzk5NkMwLjIyMjI2NSA0LjUzOTk2IDAuMDAxMjgwNTggNC43NTUyNyAwLjAwMTI4MDU3IDUuMDA2NDdDMC4wMDEyODA1NSA1LjI1NzY3IDAuMjM1MjY0IDUuNDcyOTggMC41MDgyNDQgNS40NzI5OEwxMC41OTc0IDUuNDcyOThMNy45MTk1NyA5LjMxMjY5QzcuODY3NTggOS4zODQ0NiA3Ljg0MTU4IDkuNDY4MTkgNy44NDE1OCA5LjU1MTkyQzcuODQxNTggOS42OTU0NiA3LjkxOTU3IDkuODUwOTcgOC4wNjI1NiA5LjkzNDdDOC4yODM1NSAxMC4wNjYzIDguNTk1NTIgMTAuMDA2NSA4LjczODUxIDkuODAzMTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
  display: block;
  transition: all 0.5s ease;
}
.footer-area .footer-top .footer-accordion-head.open::after {
  transform: rotate(90deg);
}
@media (min-width: 992px) {
  .footer-area .footer-top .footer-accordion-head::after {
    display: none;
  }
}
.footer-area .footer-bottom {
  background-color: #0b0e29;
  --focus-offset-color: #0b0e29;
  padding-bottom: var(--header-height);
}
@media (min-width: 992px) {
  .footer-area .footer-bottom {
    padding-bottom: 0;
  }
}
.footer-area .footer-bottom .copyright {
  color: #c9d1e3;
}
.footer-area .footer-bottom .footer-meta-link {
  font-size: 16px;
}
.footer-area .scroll-to-top {
  position: fixed;
  bottom: calc(var(--header-height) + 10px);
  right: 0;
  background-color: #001f47;
  display: block;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transform: translateY(24px);
  transition: all 500ms linear;
}
@media (min-width: 768px) {
  .footer-area .scroll-to-top {
    bottom: calc(var(--header-height) + 24px);
    right: 24px;
  }
}
@media (min-width: 992px) {
  .footer-area .scroll-to-top {
    bottom: 24px;
    right: 24px;
  }
}
.footer-area .scroll-to-top.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  z-index: 1;
}
.footer-area .scroll-to-top button {
  display: flex;
  padding: 4px;
}
.footer-area .scroll-to-top button .inline-icon {
  width: 22px;
  height: 22px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("~slick-carousel/slick/fonts/slick.eot");
  src: url("~slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("~slick-carousel/slick/fonts/slick.woff") format("woff"), url("~slick-carousel/slick/fonts/slick.ttf") format("truetype"), url("~slick-carousel/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #21b5ea;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #0075a3;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #657785;
  opacity: 1;
}

.slick-track {
  display: flex;
  align-items: center;
}
.slick-track .slick-slide {
  float: none;
  height: auto;
}

.slider-actions {
  border-top: 1px solid #c9d1e3;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 24px;
}
@media (min-width: 768px) {
  .slider-actions {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 30px;
  }
}
.slider-actions .slick-arrow {
  position: relative;
  transform: initial;
}
.slider-actions .slick-dots {
  padding-bottom: 0;
}

.slick-prev,
.slick-next {
  height: 20px;
  width: 20px;
  top: calc(100% - 20px);
}
.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  color: #c9d1e3;
}

.slick-prev {
  left: 0;
}
.slick-prev::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNDIyMTUgMC43MTczOTlMMC4xMzAxMzIgOC40NDExQy0wLjA0MzM3NzIgOC42ODUwMSAtMC4wNDMzNzcyIDkuMDEwMjIgMC4xMzAxMzIgOS4yNzQ0NUw1LjQyMjE1IDE3LjAxODVDNS42NjA3MyAxNy4zODQzIDYuMTgxMjUgMTcuNDg2IDYuNTQ5OTYgMTcuMjQyMUM2LjkxODY3IDE2Ljk5ODEgNy4wNDg4IDE2LjUzMDcgNi43ODg1NCAxNi4xODUxTDIuMzIwNjggOS42NjA2M0gxOS4xNTQxQzE5LjYzMTMgOS42NjA2MyAyMCA5LjI5NDc3IDIwIDguODY3OTRDMjAgOC40NDExIDE5LjYwOTYgOC4wNzUyNCAxOS4xNTQxIDguMDc1MjRMMi4zMjA2OCA4LjA3NTI0TDYuNzg4NTQgMS41NTA3NUM2Ljg3NTI5IDEuNDI4NzkgNi45MTg2NyAxLjI4NjUxIDYuOTE4NjcgMS4xNDQyNEM2LjkxODY3IDAuOTAwMzI5IDYuNzg4NTQgMC42MzYwOTkgNi41NDk5NiAwLjQ5MzgyQzYuMTgxMjUgMC4yNzAyMzkgNS42NjA3MyAwLjM3MTg2NSA1LjQyMjE1IDAuNzE3Mzk5WiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyKSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyIiB4MT0iLTAuNzYxOTA0IiB5MT0iLTcuNDg3NSIgeDI9IjIwLjYwNzkiIHkyPSIyMi4xNjI1IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIG9mZnNldD0iMC4wMDUzMDEwMiIgc3RvcC1jb2xvcj0iIzc5QUMyQiIvPgo8c3RvcCBvZmZzZXQ9IjAuOTI1NzA2IiBzdG9wLWNvbG9yPSIjMUFCMkU4Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==);
}
[dir=rtl] .slick-prev {
  right: 0;
}

.slick-next {
  right: 0;
}
.slick-next::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjU3NzggMC43MTc0TDE5Ljg2OTkgOC40NDExQzIwLjA0MzQgOC42ODUwMSAyMC4wNDM0IDkuMDEwMjIgMTkuODY5OSA5LjI3NDQ1TDE0LjU3NzggMTcuMDE4NUMxNC4zMzkzIDE3LjM4NDMgMTMuODE4NyAxNy40ODYgMTMuNDUgMTcuMjQyMUMxMy4wODEzIDE2Ljk5ODEgMTIuOTUxMiAxNi41MzA3IDEzLjIxMTUgMTYuMTg1MUwxNy42NzkzIDkuNjYwNjNIMC44NDU4NTZDMC4zNjg3MDggOS42NjA2MyA1LjA4OTk3ZS0wOSA5LjI5NDc3IDAgOC44Njc5NEMtNS4wODk5N2UtMDkgOC40NDExIDAuMzkwMzk2IDguMDc1MjQgMC44NDU4NTYgOC4wNzUyNEwxNy42NzkzIDguMDc1MjRMMTMuMjExNSAxLjU1MDc1QzEzLjEyNDcgMS40Mjg3OSAxMy4wODEzIDEuMjg2NTEgMTMuMDgxMyAxLjE0NDI0QzEzLjA4MTMgMC45MDAzMjggMTMuMjExNSAwLjYzNjA5OSAxMy40NSAwLjQ5MzgyQzEzLjgxODcgMC4yNzAyMzkgMTQuMzM5MyAwLjM3MTg2NSAxNC41Nzc4IDAuNzE3NFoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9Ii0wLjc2MTkwNCIgeTE9Ii03LjQ4NzUiIHgyPSIyMC42MDc5IiB5Mj0iMjIuMTYyNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBvZmZzZXQ9IjAuMDA1MzAxMDIiIHN0b3AtY29sb3I9IiM3OUFDMkIiLz4KPHN0b3Agb2Zmc2V0PSIwLjkyNTcwNiIgc3RvcC1jb2xvcj0iIzFBQjJFOCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=);
}
[dir=rtl] .slick-next {
  left: 0;
}

.slick-dots {
  position: relative;
  bottom: auto;
  display: block;
  text-align: center;
  height: 10px;
  line-height: 0;
  padding-bottom: 25px;
}
.slick-dots li {
  margin: 0;
  padding: 0 5px;
  height: auto;
  width: auto;
  /* because of the "Pfennigparade" we have to make all the "dots" of all the sliders no buttons because a
    screenreader user cannot use those buttons and it is not fair for other users to have more/other functionality */
  cursor: default;
  pointer-events: none;
}
.slick-dots li button {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #0075a3;
  padding: 0;
  /* because of the "Pfennigparade" we have to make all the "dots" of all the sliders no buttons because a
    screenreader user cannot use those buttons and it is not fair for other users to have more/other functionality */
  cursor: default;
  pointer-events: none;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button::before {
  content: "";
  height: 0;
  width: 0;
}
.bg-dark .slick-dots li button {
  background: #69a438;
}
.slick-dots li.slick-active button {
  background: #657785;
  height: 10px;
  width: 10px;
}
.bg-dark .slick-dots li.slick-active button {
  background: #c9d1e3;
}

.slick-list {
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 16px !important;
  border-bottom: 1px solid #c9d1e3;
}
@media (min-width: 768px) {
  .slick-list {
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 32px !important;
  }
}

.slick-slide {
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 768px) {
  .slick-slide {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.slick-track .slick-slide {
  align-self: stretch;
}
.slick-track .slick-slide img.lazyload,
.slick-track .slick-slide img.lazyloading {
  height: 0;
}

.teaser-slider .slick-dots {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .teaser-slider .slick-dots {
    margin-top: 32px;
  }
}
@media (min-width: 1200px) {
  .teaser-slider .slick-dots {
    margin-top: 40px;
  }
}

.slick-slider {
  z-index: 0;
}
.slick-slider:not(.can-slide) + .slider-actions {
  display: none !important;
}
.slick-slider .slick-dots {
  z-index: 1;
}
.slick-slider .slick-arrow {
  z-index: 2;
}

/* animations */
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.repeat-1 {
  animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1s {
  animation-delay: var(--animate-delay);
}

.animated.delay-2s {
  animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3s {
  animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4s {
  animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5s {
  animation-delay: calc(var(--animate-delay) * 5);
}

.animated.faster {
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.slow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*=Out] {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}