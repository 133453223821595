.footer-area {
  background-color: $color-darkblue;

  --focus-offset-color: #{$color-darkblue};

  color: $color-white;

  a {
    color: $color-white;

    &:hover {
      color: $color-skyblue-200;
    }
  }

  .footer-top {
    padding-top: 32px;
    padding-bottom: 32px;

    @include media-breakpoint-up(lg) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .social-media-component {
      border-bottom: 3px solid $color-blackblue;

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        border-bottom: none;
      }

      .social-links {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }

        ul {
          display: flex;
          gap: 16px;
        }
      }

      .social-link {
        &:hover {
          text-decoration: none;
        }

        img {
          height: 24px;
        }

        source[srcset*='.svg'] + img {
          filter: brightness(0) invert(1);
        }
      }
    }

    .language-switch-container {
      font-size: 16px;

      a {
        color: var(--accent-color);

        &:hover {
          color: var(--accent-hover-color);
        }
      }
    }

    .link-list-component {
      border-bottom: 3px solid $color-blackblue;

      @include media-breakpoint-up(lg) {
        padding-left: 20px;
        border-bottom: none;
        border-left: 3px solid $color-blackblue;
        margin-bottom: 0;
      }

      &:last-child {
        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }
      }

      .list-group {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      .list-group-item {
        font-size: 16px;
        margin-bottom: 16px;

        &:last-child {
          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
          }
        }
      }
    }

    .footer-accordion-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNzM4NTEgOS44MDMxMkwxMS45MTAzIDUuMjU3NjdDMTIuMDE0MyA1LjExNDEyIDEyLjAxNDMgNC45MjI3NCAxMS45MTAzIDQuNzY3MjNMOC43Mzg1MSAwLjIwOTgxOEM4LjU5NTUyIC0wLjAwNTQ5MzQyIDguMjgzNTUgLTAuMDY1MzAyMSA4LjA2MjU2IDAuMDc4MjM4NkM3Ljg0MTU4IDAuMjIxNzc5IDcuNzYzNTggMC40OTY4OTkgNy45MTk1NyAwLjcwMDI0OEwxMC41OTc0IDQuNTM5OTZMMC41MDgyNDQgNC41Mzk5NkMwLjIyMjI2NSA0LjUzOTk2IDAuMDAxMjgwNTggNC43NTUyNyAwLjAwMTI4MDU3IDUuMDA2NDdDMC4wMDEyODA1NSA1LjI1NzY3IDAuMjM1MjY0IDUuNDcyOTggMC41MDgyNDQgNS40NzI5OEwxMC41OTc0IDUuNDcyOThMNy45MTk1NyA5LjMxMjY5QzcuODY3NTggOS4zODQ0NiA3Ljg0MTU4IDkuNDY4MTkgNy44NDE1OCA5LjU1MTkyQzcuODQxNTggOS42OTU0NiA3LjkxOTU3IDkuODUwOTcgOC4wNjI1NiA5LjkzNDdDOC4yODM1NSAxMC4wNjYzIDguNTk1NTIgMTAuMDA2NSA4LjczODUxIDkuODAzMTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
        display: block;
        transition: all 0.5s ease;
      }

      &.open {
        &::after {
          transform: rotate(90deg);
        }
      }

      @include media-breakpoint-up(lg) {
        &::after {
          display: none;
        }
      }
    }
  }

  .footer-bottom {
    background-color: $color-blackblue;

    --focus-offset-color: #{$color-blackblue};

    padding-bottom: var(--header-height);

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }

    .copyright {
      color: $color-icegray-600;
    }

    .footer-meta-link {
      font-size: 16px;
    }
  }

  .scroll-to-top {
    position: fixed;
    bottom: calc(var(--header-height) + 10px);
    right: 0;
    background-color: $color-darkblue;
    display: block;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transform: translateY(24px);
    transition: all 500ms linear;

    @include media-breakpoint-up(md) {
      bottom: calc(var(--header-height) + 24px);
      right: 24px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 24px;
      right: 24px;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      z-index: 1;
    }

    button {
      display: flex;
      padding: 4px;

      .inline-icon {
        width: 22px;
        height: 22px;
      }
    }
  }
}
