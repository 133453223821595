/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaLTWXX-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Incn */

@font-face {
  font-family: 'museo-sans';
  src: url('../../fonts/MuseoSans500.woff2') format('woff2'), url('../../fonts/MuseoSans500.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'museo-slab';
  src: url('../../fonts/MuseoSlab700.woff2') format('woff2'), url('../../fonts/MuseoSlab700.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../../fonts/MuseoSans700.woff2') format('woff2'), url('../../fonts/MuseoSans700.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'museo-slab';
  src: url('../../fonts/MuseoSlab500.woff2') format('woff2'), url('../../fonts/MuseoSlab500.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'museo-slab';
  src: url('../../fonts/MuseoSlab900.woff2') format('woff2'), url('../../fonts/MuseoSlab900.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../../fonts/MuseoSans500Italic.woff2') format('woff2'),
    url('../../fonts/MuseoSans500Italic.woff') format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'museo-sans';
  src: url('../../fonts/MuseoSans700Italic.woff2') format('woff2'),
    url('../../fonts/MuseoSans700Italic.woff') format('woff');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}
