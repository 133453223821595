$slick-arrow-color: $color-skyblue-200;
$slick-arrow-color-not-active: $color-icegray-600;
$slick-arrow-border-color: $color-skyblue-200;
$slick-arrow-border-color-not-active: $color-icegray-600;
$slick-dot-color: $color-skyblue-600;
$slick-dot-color-active: $color-gray-600;
$slick-dot-size: 6px;
$slick-arrow-size: 20px;
$slick-arrow-font-size: 30px;
$slick-arrow-font-weight: $font-weight-bolder;
$slick-arrow-font-line-height: 40px;
$slick-arrow-external-container-margin: 18px;
$slick-dots-size: 10px;
$slick-dots-size-active: 10px;
$slick-dots-spacer: 10px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 1;
$slick-loader-path: '../../images/';

@import 'slick-blueprint';

.slider-actions {
  border-top: 1px solid $color-icegray-600;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 24px;

  @include media-breakpoint-up(md) {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 30px;
  }

  .slick-arrow {
    position: relative;
    transform: initial;
  }

  .slick-dots {
    padding-bottom: 0;
  }
}

.slick-prev,
.slick-next {
  height: $slick-arrow-size;
  width: $slick-arrow-size;
  top: calc(100% - #{$slick-arrow-size});

  &.slick-disabled::before {
    color: $slick-arrow-color-not-active;
  }
}

.slick-prev {
  left: 0;

  &::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNDIyMTUgMC43MTczOTlMMC4xMzAxMzIgOC40NDExQy0wLjA0MzM3NzIgOC42ODUwMSAtMC4wNDMzNzcyIDkuMDEwMjIgMC4xMzAxMzIgOS4yNzQ0NUw1LjQyMjE1IDE3LjAxODVDNS42NjA3MyAxNy4zODQzIDYuMTgxMjUgMTcuNDg2IDYuNTQ5OTYgMTcuMjQyMUM2LjkxODY3IDE2Ljk5ODEgNy4wNDg4IDE2LjUzMDcgNi43ODg1NCAxNi4xODUxTDIuMzIwNjggOS42NjA2M0gxOS4xNTQxQzE5LjYzMTMgOS42NjA2MyAyMCA5LjI5NDc3IDIwIDguODY3OTRDMjAgOC40NDExIDE5LjYwOTYgOC4wNzUyNCAxOS4xNTQxIDguMDc1MjRMMi4zMjA2OCA4LjA3NTI0TDYuNzg4NTQgMS41NTA3NUM2Ljg3NTI5IDEuNDI4NzkgNi45MTg2NyAxLjI4NjUxIDYuOTE4NjcgMS4xNDQyNEM2LjkxODY3IDAuOTAwMzI5IDYuNzg4NTQgMC42MzYwOTkgNi41NDk5NiAwLjQ5MzgyQzYuMTgxMjUgMC4yNzAyMzkgNS42NjA3MyAwLjM3MTg2NSA1LjQyMjE1IDAuNzE3Mzk5WiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyKSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyIiB4MT0iLTAuNzYxOTA0IiB5MT0iLTcuNDg3NSIgeDI9IjIwLjYwNzkiIHkyPSIyMi4xNjI1IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIG9mZnNldD0iMC4wMDUzMDEwMiIgc3RvcC1jb2xvcj0iIzc5QUMyQiIvPgo8c3RvcCBvZmZzZXQ9IjAuOTI1NzA2IiBzdG9wLWNvbG9yPSIjMUFCMkU4Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==);
  }

  [dir='rtl'] & {
    right: 0;
  }
}

.slick-next {
  right: 0;

  &::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjU3NzggMC43MTc0TDE5Ljg2OTkgOC40NDExQzIwLjA0MzQgOC42ODUwMSAyMC4wNDM0IDkuMDEwMjIgMTkuODY5OSA5LjI3NDQ1TDE0LjU3NzggMTcuMDE4NUMxNC4zMzkzIDE3LjM4NDMgMTMuODE4NyAxNy40ODYgMTMuNDUgMTcuMjQyMUMxMy4wODEzIDE2Ljk5ODEgMTIuOTUxMiAxNi41MzA3IDEzLjIxMTUgMTYuMTg1MUwxNy42NzkzIDkuNjYwNjNIMC44NDU4NTZDMC4zNjg3MDggOS42NjA2MyA1LjA4OTk3ZS0wOSA5LjI5NDc3IDAgOC44Njc5NEMtNS4wODk5N2UtMDkgOC40NDExIDAuMzkwMzk2IDguMDc1MjQgMC44NDU4NTYgOC4wNzUyNEwxNy42NzkzIDguMDc1MjRMMTMuMjExNSAxLjU1MDc1QzEzLjEyNDcgMS40Mjg3OSAxMy4wODEzIDEuMjg2NTEgMTMuMDgxMyAxLjE0NDI0QzEzLjA4MTMgMC45MDAzMjggMTMuMjExNSAwLjYzNjA5OSAxMy40NSAwLjQ5MzgyQzEzLjgxODcgMC4yNzAyMzkgMTQuMzM5MyAwLjM3MTg2NSAxNC41Nzc4IDAuNzE3NFoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9Ii0wLjc2MTkwNCIgeTE9Ii03LjQ4NzUiIHgyPSIyMC42MDc5IiB5Mj0iMjIuMTYyNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBvZmZzZXQ9IjAuMDA1MzAxMDIiIHN0b3AtY29sb3I9IiM3OUFDMkIiLz4KPHN0b3Agb2Zmc2V0PSIwLjkyNTcwNiIgc3RvcC1jb2xvcj0iIzFBQjJFOCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=);
  }

  [dir='rtl'] & {
    left: 0;
  }
}

.slick-dots {
  position: relative;
  bottom: auto;
  display: block;
  text-align: center;
  height: $slick-dots-size-active;
  line-height: 0;
  padding-bottom: 25px;

  li {
    margin: 0;
    padding: 0 $slick-dots-spacer/2;
    height: auto;
    width: auto;

    /* because of the "Pfennigparade" we have to make all the "dots" of all the sliders no buttons because a
      screenreader user cannot use those buttons and it is not fair for other users to have more/other functionality */
    cursor: default;
    pointer-events: none;

    button {
      height: $slick-dots-size;
      width: $slick-dots-size;
      border-radius: 50%;
      background: $slick-dot-color;
      padding: 0;

      /* because of the "Pfennigparade" we have to make all the "dots" of all the sliders no buttons because a
        screenreader user cannot use those buttons and it is not fair for other users to have more/other functionality */
      cursor: default;
      pointer-events: none;

      &:hover,
      &:focus {
        outline: none;
      }

      &::before {
        content: '';
        height: 0;
        width: 0;
      }

      .bg-dark & {
        background: $color-green-500;
      }
    }

    &.slick-active {
      button {
        background: $slick-dot-color-active;
        height: $slick-dots-size-active;
        width: $slick-dots-size-active;

        .bg-dark & {
          background: $color-icegray-600;
        }
      }
    }
  }
}

.slick-list {
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 16px !important;
  border-bottom: 1px solid $color-icegray-600;

  @include media-breakpoint-up(md) {
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 32px !important;
  }
}

.slick-slide {
  padding-left: 8px;
  padding-right: 8px;

  @include media-breakpoint-up(md) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.slick-track {
  .slick-slide {
    align-self: stretch;

    img.lazyload,
    img.lazyloading {
      height: 0;
    }
  }
}

.teaser-slider {
  .slick-dots {
    margin-top: 24px;

    @include media-breakpoint-up(md) {
      margin-top: 32px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 40px;
    }
  }
}

.slick-slider {
  z-index: 0;

  &:not(.can-slide) {
    + .slider-actions {
      display: none !important;
    }
  }

  .slick-dots {
    z-index: 1;
  }

  .slick-arrow {
    z-index: 2;
  }
}
