$slick-font-path: "~slick-carousel/slick/fonts/" !default;
$slick-loader-path: "~slick-carousel/slick/" !default;
$slick-arrow-color: black !default;
@import 'node_modules/slick-carousel/slick/slick';
@import 'node_modules/slick-carousel/slick/slick-theme';

.slick-track {
  display: flex;
  align-items: center;

  .slick-slide {
    float: none;
    height: auto;
  }
}
